/* eslint-disable camelcase */
import request from '@/utils/request';

import { GETResponse, BaseUserInfo } from './response.type';

interface Roles extends GETResponse {
  id: number,
  szid: number,
  group_name: string,
  type?: number,
}

export interface UserInfo extends GETResponse, BaseUserInfo {
  id: number,
  safety_at?: string;
  user_name: string,
  group?: Roles[],
  admin: number,
  protocol: number,
  status_id: number,
  staff_type_id: number,
  department: string,
  position_name: string,
  clan_name: string,
}

export async function getInfo(): Promise<UserInfo> {
  const res = await request.get('/api/userinfo');

  return res.data;
}

export function logout() {
  window.location.href = '/api/logout';
}

export interface RtxData {
  rtx: string,
  pinyin: string,
  chn_name: string,
}

export async function getRtxData(name: string, size?: number): Promise<RtxData[]> {
  const res = await request.get('/api/rtx/search', { params: { name, size } });

  return res.data;
}

export async function getRtxDataInstead(name: string, size?: number): Promise<RtxData[]> {
  const res = await request.get('/api/webapi/report/rtx/search', { params: { name, size } });

  return res.data;
}

export async function rtxValidate(name: string[]): Promise<boolean> {
  const res = await request.post('/api/rtx/detectRtx', { name });

  return res.data;
}

interface PayUserFirstLogin {
  message: string,
  version: string,
  is_first: boolean,
}
// 9.4：是否要弹出用户须知
// 9.5：是否要弹出关注窗口
export async function getIsPayUserFirstLogin(): Promise<PayUserFirstLogin[]> {
  const res = await request({
    method: 'POST',
    url: '/api/user/behavior',
    data: { versions: ['9.4', '9.5'] },
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res.data;
}
