import React, { FC } from 'react';
import styles from './index.module.less';

interface DefaultBaseProps {
  baseImg: string,
  width?: number,
  title?: string,
  subTitle?: string,
  style?: any,
  containerStyle?: any,
}

const DefaultBase: FC<DefaultBaseProps> = ({
  baseImg, width, title, style, subTitle, containerStyle,
}: DefaultBaseProps) => (
  <div className={styles.container} style={{ ...containerStyle }}>
    <div className={styles.content}>
      <div className={styles.introImg} style={{ width }}>
        <img
          src={baseImg}
          className={styles.baseImg}
          alt="洞察"
        />
      </div>
      {title && <p className={styles.introTitle} style={{ ...style }}>{title}</p>}
      {subTitle && <p className={styles.subTitle} style={{ ...style }}>{subTitle}</p>}
    </div>
  </div>
);

export default DefaultBase;
