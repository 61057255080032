/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React, { FC, useEffect, useState } from 'react';
import { Alert, Button } from 'antd';
import { ReportPreviewData } from '@/services/report-library';
import { useTranslation } from 'react-i18next';
import { PhotoSlider } from 'react-photo-view';
import { UserInfoContext, BeaconContext } from '@/context';
import advertiseBg from '@/assets/images/advertiseBg.png';
import lockIcon from '@/assets/images/lock.png';
import styles from '../index.module.less';
import 'react-photo-view/dist/react-photo-view.css';

interface Props {
  data: ReportPreviewData,
  changeVisible: (v: boolean) => void,
  anchor: number,
}

const FullReportPreview: FC<Props> = ({ data, changeVisible, anchor }: Props) => {
  const { t } = useTranslation();
  const { beacon } = BeaconContext.useContainer();
  const { info } = UserInfoContext.useContainer();
  const [visible, setVisible] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(0);
  const initImages = data?.preview_pic?.length
    ? [...data.preview_pic, advertiseBg].map((item, index) => ({ images: item, id: index * -1 }))
    : [{ images: advertiseBg, id: -5 }];
  const [previewImages, setPreviewImages] = useState(
    data?.preview_pic?.length ? [...data.preview_pic, advertiseBg] : [advertiseBg],
  );

  const handleImgClick = (item: string, index: number) => {
    previewImages[index] = item;
    setPreviewImages(previewImages);
    setPreviewIndex(index);
    setVisible(true);
  };

  const handlePreviewImageChange = (index: number) => {
    setPreviewIndex(index);
  };

  useEffect(() => {
    if (anchor) {
      let img;
      if (anchor > 5) {
        img = document.getElementById('preview-6');
      } else {
        img = document.getElementById(`preview-${anchor}`);
      }
      if (img) img.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }, [anchor]);

  return (
    <div>
      {!data.preview_pic && (
        <Alert
          message={t('tips')}
          description={t('wait_generating_tips')}
          type="info"
          showIcon
        />
      )}
      {data?.preview_pic && (
        <section
          className={`${styles.section} ${styles.previewSection}`}

        >
          {initImages?.length
            ? (
              <>
                {initImages.map(((item, index) => (
                  <img
                    id={`preview-${index + 1}`}
                    key={`${index}img`}
                    className={styles.previewImg} src={item.images}
                    alt="预览图"
                    onClick={() => handleImgClick(item.images, index)}
                  />
                )))}
              </>
            ) : null}
          <div className={styles.unlock} id="preview-6">
            <Button
              onClick={() => {
                changeVisible(true);
                beacon.onDirectUserAction(
                  'click_report_purchase_page_inquiry_button',
                  { user_id: info?.user_id, report_id: data.openid },
                );
              }}
              className={styles.unlockButton}
            >
              <div>
                <img
                  src={lockIcon}
                  alt=""
                  className={styles.unlockButtonImg}
                />
                {t('click_purchase_process')}
              </div>
              <div className={styles.loadingMoreTips}>
                （禁止copy及大范围传播）
              </div>
            </Button>
          </div>
          <PhotoSlider
            images={previewImages.map((item, index) => ({ src: item, key: item + index }))}
            visible={visible}
            onClose={() => setVisible(false)}
            index={previewIndex}
            onIndexChange={(index: number) => handlePreviewImageChange(index)}
            photoClassName={styles.previewPhoto}
            maskClassName={styles.mask}
          />
        </section>
      )}
    </div>
  );
};

export default FullReportPreview;
