/* eslint-disable camelcase */
import request from '@/utils/request';
import { removeObjectFalsyKey, filterRtx, checkWebpSupport } from '@/utils/helper/base';
import { TableBaseParams } from '@/configs/type';
import { CURRENT_LANGUAGE } from '@/i18n';
// import configs from '@/configs';
import { LabelGroupDataItem } from '@/services/knowledge/label';
import { TreeNodeProps } from './report-library-admin';
import { SuperiorData, ApplyPermissionData, UserPreferenceOperate } from './common';
import { PaginationRequest, PaginationResponse, WriterOrCharger } from './response.type';

export type SearchKey = string;

export type Tag = string;

export interface ReportItemData {
  id: number,
  title: string,
  tag: Tag[],
  write_by: WriterOrCharger,
  cover_pic: string,
  cover_pic_type: number
  created_at: string,
  output_at: string,
  openid: string;
}

export interface ReportData {
  data: ReportItemData[],
  total: number
}

export interface SearchReportItemData extends ReportItemData {
  report_type: string,
  booked_num: number,
  like_num: number,
  views_num: number,
  introduction: string,
  favorites_num: number,
  like_status: boolean,
  favorites_status: boolean,
  type: string,
  preview_pic: string[] | null,
  read_auth: number,
  media_id?: string,
}

export interface SearchReportData {
  data: SearchReportItemData[],
  key?: string[],
  total: number,
}

export type Classification = 'application' | 'category' | 'industry'
| 'terminal' | 'area' | 'crowd' | 'research_type' | 'series';

export interface SearchReportParams extends TableBaseParams {
  keyword?: string,
  order?: any,
  type?: string[],
  application?: string[],
  category?: string[],
  industry?: string[],
  terminal?: string[],
  area?: string[],
  crowd?: string[],
  research_type?: string[],
  series?: string[],
  ['tag.keyword']?: string[],
  ['write_by.user_id']?: string,
  buy?: number,
  subscribed?: string,
  ext_field?: string[],
  lang?: string,
  needUpdate?: boolean,
  total?: number,
  report_type?: string,
}

export async function getReportSearchData(searchReportParams: SearchReportParams): Promise<SearchReportData> {
  const params = { ...searchReportParams };
  delete params.needUpdate;
  delete params.total;
  if (params.keyword) {
    params.keyword = await filterRtx(params.keyword);
  }

  const res = await request.post(
    `/api/webapi/report/search?sys_lang=${CURRENT_LANGUAGE}`,
    removeObjectFalsyKey(params),
  );
  return res.data;
}

export async function getOtherReportData(page: number, pageSize = 8, type?: string): Promise<ReportData> {
  const res = await request.get(
    '/api/webapi/report/newestReport',
    { params: removeObjectFalsyKey({ page, page_size: pageSize, type }) },
  );
  return res.data;
}

export interface ReportClassificationItem {
  id: number,
  name: string,
}

// eslint-disable-next-line max-len
export type ReportClassificationName = 'research_type' | 'application' | 'category' | 'terminal' | 'industry' | 'area' | 'crowd';

export type ReportClassificationData = {
  [key: string]: ReportClassificationItem[];
};

export async function getClassification(isAdmin: boolean): Promise<ReportClassificationData> {
  const res = await request.get('/api/commonapi/report/getClassification', {
    params: { sys_lang: isAdmin ? 'zh' : CURRENT_LANGUAGE },
  });

  return res.data;
}

export async function getExtClass(): Promise<LabelGroupDataItem[]> {
  const env = window.location.pathname.split('/')[1] === 'admin';
  const res = await request.get(
    '/api/commonapi/classification/getExt', { params: { sys_lang: env ? 'zh' : CURRENT_LANGUAGE, type: 0 } },
  );

  return res.data;
}

export interface TagItem {
  id: number,
  name: string,
  num: number,
}

export async function getTagList(word: string, size?: number): Promise<TagItem[]> {
  const res = await request.get('/api/adminapi/tag/tagList', { params: { word, size } });
  return res.data;
}

export interface ReportPreviewData {
  menus: TreeNodeProps;
  media_id?: string;
  media_cover?: string;
  application: string[],
  category: string[],
  area: string[],
  crowd: string[],
  like_num: number,
  views_num: number,
  title: string,
  created_at: string,
  introduction_tag: string,
  preview_pic: string[] | null,
  type: string,
  write_by: WriterOrCharger[],
  charge_by: WriterOrCharger[],
  read_authority: number,
  isLiked: number,
  pdfUrl: string,
  read_auth: number,
  favorites_num: number,
  booked_num?: number,
  tag: string[],
  output_at: string,
  file_path?: string,
  openid?: string,
  id?: string,
  lang: string,
  mul_openid?: string,
  created_by: WriterOrCharger[];
}

const headers = { Accept: 'application/json, text/plain,*/*' };
const setHeaders = async () => {
  const isSupport = await checkWebpSupport();
  if (isSupport) {
    headers.Accept = 'application/json, text/plain,image/webp*/*';
  }
};
export async function getReportPreviewDetail(openid: string): Promise<ReportPreviewData> {
  await setHeaders();
  const res = await request.get(`/api/webapi/report/preview/${openid}`, { headers });
  return res.data;
}

export async function checkReportDetail(openid: string): Promise<string> {
  const res = await request.get(`/api/adminapi/report/check/${openid}`);
  return res.data;
}

export async function getReportOperate(openid: string, field: string[]): Promise<UserPreferenceOperate> {
  const res = await request.post('/api/webapi/report/hadCul', { openid, field });
  return res.data;
}

export async function updateReportOperate(openid: number | string, field: Object) {
  const res = await request.post('/api/webapi/report/cul',
    { openid, field });
  return res.data;
}

export interface SectionData {
  data: ReportItemData[],
  month_growth: number,
  name: string,
  total: number,
}

export async function getSectionData(type: string): Promise<SectionData[]> {
  const res = await request.get(`/api/webapi/report/recommendation?type=${type}`);

  return res.data;
}

interface ReportImageData {
  id: number,
  rid: number,
  images: string[],
  sort: number,
  created_at: string,
  updated_at: string,
  openid?: string
}

export async function getReportImage(rid: number, page: number, pageSize: number): Promise<ReportImageData> {
  const res = await request.get('/api/webapi/images/list', { params: { rid, page, page_size: pageSize } });

  return res.data;
}

export interface ReadAuthData{
  uid?: number,
  object_id?: number,
  type?: number,
  status?: number,
  reason?: string,
  updated_at?: string,
  created_at?: string,
  id?: number,
}
export async function applyPermission(params: ApplyPermissionData): Promise<ReadAuthData> {
  const res = await request.post('/api/webapi/report/auth', params);

  return res.data;
}

export interface ReportImages {
  id: number,
  rid: number,
  images: string,
  sort: number,
  created_at: string,
  updated_at: string,
}

export async function getReportImages(openid: string, page: number, page_size = 5): Promise<ReportImages[]> {
  await setHeaders();
  const res = await request.get(`/api/webapi/images/list?openid=${openid}
  &page=${page}&page_size=${page_size}`, { headers });
  return res.data;
}

export async function getApprover(openid: string): Promise<SuperiorData> {
  const res = await request.get(`/api/webapi/report/auth/description?openid=${openid}`);
  return res.data;
}

export async function getApproverSingle(openid: string, szid: string, type = 1): Promise<SuperiorData> {
  const res = await request.get(`/api/szweb/report/auth/description?openid=${openid}&szid=${szid}&type=${type}`);
  return res.data;
}

export async function handleAuthSingle(openid: string, reason: string, szid: string, type = 1) {
  const res = await request({
    url: '/api/szweb/report/auth',
    method: 'POST',
    headers: { 'content-type': 'application/json', szid },
    data: { openid, reason, type },
  });
  return res.data;
}

export async function buyReport(openid: string): Promise<Boolean> {
  const res = await request.get(`/api/webapi/report/buy/${openid}`);
  return res.data;
}

export async function addReadSurvey(params: { openid: string, msg: string, direction?: string }): Promise<Boolean> {
  const res = await request.post('/api/webapi/report/addReadSurvey', params);
  return res.data;
}

export async function getReadSurvey(openid: string): Promise<{ msg: string, direction?: string }> {
  const res = await request.get(`/api/webapi/report/viewReadSurvey/${openid}`);
  return res.data;
}

export interface ReprotPriceData{
  all?: number,
  price?: number,
}
export async function getReportPriceInfo(openid: string): Promise<ReprotPriceData> {
  const res = await request.get(`/api/webapi/report/getSsoCompanyBalance/${openid}`, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: { unused: 0 },
  });
  return res.data;
}

// 下载
export async function downloadReport(id: number): Promise<Boolean> {
  await request.get(`/api/webapi/report/download/${id}`);

  return true;
}

// 单品专区报告下载
export async function downloadReportSingle(id: number, szid: string): Promise<Boolean> {
  try {
    const { data } = await request.get(`/api/szweb/report/download/${id}?szid=${szid}`);
    if (!data?.status) throw data.msg;
    return true;
  } catch {
    return false;
  }
}

export interface DownloadItemData {
  id: number,
  rid: number,
  uid: number,
  status: number, // 状态 0：未处理  1：已处理  2:已失效
  created_at: string,
  updated_at: string,
  title: string,
  progress: string | boolean,
  openid: string[],
  data: string,
}

interface DownloadList extends PaginationResponse {
  data: DownloadItemData[],
}

export interface DownloadListParams extends PaginationRequest {
  word?: string,
}

export async function getDownloadList(params: DownloadListParams): Promise<DownloadList> {
  const res = await request.get('/api/webapi/personal/download/list', { params: removeObjectFalsyKey(params) });

  return res.data;
}

export function getDownloadUrl(rid: number): string {
  return `/api/webapi/personal/download/getPdf/${rid}`;
}

interface UpdateAllRes {
  data?: boolean,
  message?: string,
  status?: string,
}

// 订阅标签
export async function updateSubscribed(word: string, subscribed: number): Promise<UpdateAllRes> {
  const res = await request.post('/api/webapi/report/subscribed', { subscribed, word: [word] });
  return res.data;
}

// 获取标签订阅状态
export async function getSubscribed(word: string): Promise<Boolean> {
  const res = await request.get(`/api/webapi/report/subscribedStatus?word=${word}`);
  return res.data;
}

// 获取作者订阅状态
export async function getWriter(writer: string): Promise<Boolean> {
  const res = await request.get(`/api/webapi/report/subscribedStatus?writer=${writer}`);
  return res.data;
}

// 标签搜索接口
export async function tagSearch(keyword: string): Promise<string[]> {
  const res = await request.post('/api/webapi/report/tag/search', { keyword });
  return res.data;
}

interface TagNumItem {
  report: number,
  culture: number,
  tag: string,
}

// 标签数量搜索接口
export async function tagNumSearch(tag: string[]): Promise<TagNumItem[]> {
  const res = await request.post('/api/webapi/report/tagNum', { tag });
  return res.data;
}

export interface FullReportResponseData {
  rid: number,
  title: string,
  pic: string,
  sort: number,
  content: string[],
  created_at: string,
  updated_at: string,
  created_timestamp: number,
  updated_timestamp: number,
  id: number,
}

interface FullReportResponse {
  total?: number,
  data: FullReportResponseData[]
}

// 报告全文搜索接口
export async function fullReportSearch(searchParams: any): Promise<FullReportResponse> {
  const params = { ...searchParams };
  delete params.total;
  delete params.needUpdate;
  const res = await request.post('/api/webapi/reportPage/search', params);
  return res.data;
}

export interface DataSearchResponseData {
  cover_pic: string;
  title: string[] | string,
  level_name: string,
  created_at: string,
  name: string,
  introduction: string,
  id: number,
}

interface DataSearchResponse {
  total?: number,
  data: DataSearchResponseData[]
}

// 数据报表接口
export async function dataSearch(searchParams: any): Promise<DataSearchResponse> {
  const params = { ...searchParams };
  delete params.total;
  delete params.needUpdate;
  const res = await request.post('/api/webapi/data/basic/search', params);
  return res.data;
}
